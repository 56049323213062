import React, { useState, useEffect } from 'react'
import { Dropdown, Avatar,Modal } from 'antd';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { 
	EditOutlined, 
	SettingOutlined, 
	ShopOutlined, 
	QuestionCircleOutlined, 
	LogoutOutlined,
	CaretDownFilled
} from '@ant-design/icons';
import NavItem from './NavItem';
import Flex from 'components/shared-components/Flex';
import { signOut } from 'store/slices/authSlice';
import styled from '@emotion/styled';
import { FONT_WEIGHT, MEDIA_QUERIES, SPACER, FONT_SIZES } from 'constants/ThemeConstant'

const Icon = styled.div(() => ({
	fontSize: FONT_SIZES.LG
}))

const Profile = styled.div(() => ({
	display: 'flex',
	alignItems: 'center'
}))

const UserInfo = styled('div')`
	padding-left: ${SPACER[2]};
	display: flex;
	align-items: center;
	flex-direction: column;
	@media ${MEDIA_QUERIES.MOBILE} {
		display: none
	}
`

const Name = styled.div(() => ({
	// fontWeight: FONT_WEIGHT.SEMIBOLD,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90px",
    fontSize: "14px"
}))

const Title = styled.span(() => ({
	opacity: 0.9,
	fontSize: "9px",
    backgroundColor: "rgb(0 0 0/7%)",
    maxWidth: "80px",
    color: "rgb(0 0 0/85%)",
    padding: "3px",
    borderRadius: "4px",
    textAlign: "center"
}))

// const MenuItem = (props) => (
// 	<Flex as="a" href={props.path} alignItems="center" gap={SPACER[2]}>
// 		<Icon>{props.icon}</Icon>
// 		<span>{props.label}</span>
// 	</Flex>
// )

const MenuItemSignOut = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleSignOut = () => {
		// dispatch(signOut())
		Modal.confirm({
			title: 'Confirm',
			content: 'Do you want to log out?',
			okText:'Yes',
			cancelText:'No',
			onOk:() =>{
			clearAll()
			}
		});
	}

	const clearAll = () =>{
		// const apiToken = localStorage.getItem('apiToken');
		const unique_id = localStorage.getItem('unique_id');
		if (unique_id) {
			// localStorage.removeItem('apiToken');
			localStorage.removeItem('unique_id');
			localStorage.removeItem('fullName');
			localStorage.removeItem('roleName');
			// navigate('/login?apiToken=&userId=');
			window.location.href = 'https://web.callhippo.com';
		}
	}
	return (
		<div onClick={handleSignOut}>
			<Flex alignItems="center" gap={SPACER[2]} >
				<Icon>
					<LogoutOutlined />
				</Icon>
				<span>{props.label}</span>
			</Flex>
		</div>
	)
}

const items = [
	{
		key: 'LogOut',
		label: <MenuItemSignOut label="Logout" />,
	}
]

export const NavProfile = ({mode}) => {
	const fullName = localStorage.getItem('fullName') || '';
	const [userName, setUserName] = useState(() => fullName.charAt(0).toUpperCase() + fullName.slice(1));
	const [role, setRole] = useState(() => localStorage.getItem('roleName') || '');
	const [userNameFirstLetter, setUserNameFirstLetter] = useState('');

	useEffect(() => setUserNameFirstLetter(userName.charAt(0)?.toUpperCase()), [userName]);

	return (
		<Dropdown placement="bottomRight" menu={{items}} trigger={["click"]}>
			<NavItem mode={mode}>
				<Profile>
					{/* <Avatar src="/img/avatars/thumb-1.jpg" /> */}
					<span className="nmusercircle">{userNameFirstLetter}</span>
					<UserInfo className="profile-text">
						<Name>{userName}</Name>
						{role ? <Title>{localStorage.getItem('roleName')}</Title>:''}
					</UserInfo>
					<div className='arrowProfIcon'><CaretDownFilled /></div>
				</Profile>
			</NavItem>
		</Dropdown>
	);
}

export default NavProfile
